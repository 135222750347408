.container {
    font-family: 'Gotham', sans-serif;
}

.container {
  width: 1400px;
  position: relative;
  margin: 0 auto;
  background: #fff; }

  @media screen and (max-width: 992px) {
  .container {
    width: calc(100% - 40px);
    max-width: 100%; }

  .signup-content {
    flex-direction: column;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    -o-flex-direction: column;
    -ms-flex-direction: column; }

  .signup-img, .signup-form {
    width: 100%; } }

    display-flex, .signup-content i {
  display: flex;
  display: -webkit-flex; }

  .signup-img {
  width: 50%; }

  .signup-img {
  margin-bottom: -7px; }

  img {
  max-width: 100%;
  height: auto; }

  @media (max-width:629px) {
  img#girls-images {
    display: none;
  }
}